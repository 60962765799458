<template>
  <div class="about">
    <h1>About PocketLang.com</h1>
    <p>
      The website is still work-in-progress, but we should have something useful to present soon.
    </p>
    <p>
      We decided to build PocketLang because we found the existing Thai teaching material to be very lackluster. We believe that Thai is a language that is best learnt by memorizing complete sentences that you can use in your daily life. By doing so, then you will not only gain a feeling of the grammar, but also learn individual words and gain the ability to construct your own sentences.
    </p>
    <p>
      We hope and believe that PocketLang will go a long way towards becoming fluent in Thai, and that people who doesn't know a single Thai word can still find the website extremely useful as they can quickly search for a sentence they want to speak and repeat the recordings of a native speaker.
    </p>
    <h2>Why use PocketLang instead of Google Translate?</h2>
    <p>
      With PocketLang then you can be certain that all the sentences are correct and expressed as a local. The same cannot be said for Google Translate that fail to use the correct phrase for even something as simple and common as "good night", where Google Translate present a more formal version that people won't be using, rather than a Thai phrase that represent both "good night" and "sweet dreams". At PocketLang we translate Thai phrases to English, rather than the other way around. We do this to ensure that the sentences presented are actually sentences that locals would use, and they include food and locations that are relevant to living in Thailand.
    </p>
    <p>
      We also record each sentence in full, whereas Google will record each word individually - this means that our sentences are gauranteed to be smooth with correct tones and pace, whereas Google's will often be robotic with bad pauses, lack of tones, etc.
    </p>
  </div>
</template>

<script>
export default {
  name: 'about',
  mounted() {
    document.title = "About PocketLang.com"
  },
}
</script>

<style scoped lang="scss">
.about {
  margin: 0 .5rem;
}
</style>